
@media (max-width: 768px)  {
  .cs-message-input{
    border: 0px!important;
    width: 100%!important;
  }
}
canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
.center-container{
  
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 98%;
  width: 100%;
}
.cs-chat-container{
  height: 80%;
  margin-top: 30px;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
button#clearButton {
  background: red;
  color: white;
}
button#downloadButton {
  background: deepskyblue;
  color: wheat;
}
.title-bar {
  padding: 200px;

  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.title-bar {
  padding: 10px;

  width: 100%;
  border-bottom: 1px solid #08a8f1;
}
.message-input-container{
  border-radius: 20px;
  margin-top: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: white;
  border: 1px solid #08a8f1;
  border-radius: 10px;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.message-form{
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fefefe;
}

.text-field{
  border-radius: 6px;
  width: 100%;
  overflow: auto;
  
  border: 0px;
  background: white;
  color: black;
  resize: none;

  font-size: 1.2rem;
  padding: 0.5rem;
  outline: none;
}


.submit {

  align-items: center;
  background-color: transparent;
  justify-content: center;
  border-radius: 1000px;
  border: 0;
   margin-left: 10px;
   height: 40;
   width: 40;
}

#clear-chat {
  background: #e99600;
}
.menu-buttons {
  color: #FFF;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}


.message {
  box-sizing: border-box;
  background: linear-gradient(180deg, #003C56 0%, rgba(0, 60, 86, 0.83) 100%);
  padding: 16px 20px;
  border-radius: 5px;
  margin-bottom: 15px;
  
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0 0px;
  align-items: left;
  box-shadow: 3px 4px 19px inset #002c3e;
  width: 99%;
  border-left: 6px solid #08a8f1;
}

h3.heading{
  margin: 0px!important;
}
.message.sender{
  border-left: 6px solid #08a8f1;
}
.message.receiver{
  border-left: 6px solid #daf108;
}
.message.receiver h3.heading{
  color: #daf108;
}

.message.sender h3.heading{
  color: #08a8f1;
}

.scrollbar-container.cs-message-list__scroll-wrapper.ps {
  padding: 20px;
  background-color:rgb(192, 255, 255);
}

#logout{
  margin-left: auto;
  margin-right: 20px;
  color: grey;
  background: rgb(223, 222, 222);
}